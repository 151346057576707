import React from 'react';
import Spec from '../pages/spec/Spec';
import Inventory from '../pages/inventory/Inventory';
import Grade from '../pages/grade/Grade';
import Form from '../pages/form/Form';
import ServicesSingle from '../pages/services-single/services-single';
import SinglePage from '../pages/single-page/single-page';
import Tools from '../pages/tools/tools';
import Services from '../pages/service/service';
import Home from '../components/Home';
import ProcessesSingle from '../pages/processes-single/processes-single';

const routes = [
  { component: Home, path: '/', name: 'Home' },
  { component: Grade, path: '/inventory/:metal/grade/:grade', name: 'Grades' },
  { component: Form, path: '/inventory/:metal/form/:form', name: 'Forms' },
  { component: Spec, path: '/inventory/:metal/specs/:spec', name: 'Specs' },
  {
    component: Inventory,
    path: '/inventory/',
    name: 'Inventory',
    meta: [
      {
        property: 'og:description',
        content:
          'Universal Metals inventory page for Alloy, Stainless Steel, Cobalt, Nickel, and Titanium metals. Come see our grades and specs associated to each',
      },
      {
        property: 'og:url',
      },
    ],
  },
  {
    component: Inventory,
    path: '/inventory/:metal',
    name: 'Inventory',
    meta: [
      {
        property: 'og:description',
        content:
          'Universal Metals inventory page for Alloy, Stainless Steel, Cobalt, Nickel, and Titanium metals. Come see our grades and specs associated to each',
      },
      {
        property: 'og:url',
      },
    ],
  },
  {
    component: Services,
    path: '/services/',
    name: 'Services',
    meta: [
      {
        property: 'og:description',
        content:
          'Universal Metal services we provide are value added, In-house saw cutting, and material management.',
      },
      {
        property: 'og:url',
      },
    ],
  },
  { component: ServicesSingle, path: '/services/:service', name: 'Services' },
  { component: Tools, path: '/tools', name: 'Tools', exact: false },
  { component: SinglePage, path: '/:pageSlug', exact: true },
  { component: ProcessesSingle, path: '/services/value-added-processes/:service', name: 'Processes' },
  {
    component: ({ match }) => <h1>404 {match.params.slugFailed} not found</h1>,
    path: '/404/:slugFailed',
    name: '404',
    exact: true,
  },
  { component: () => <h1>404</h1>, path: '*', name: '404' },
];

export default routes;
