import WPAPI from 'wpapi';

// const prod = 'https://www.universalmetals.com/wp-json/';

const env = process.env.REACT_APP_ENV;

const local = 'http://localhost:8080/';
const ENVIRONMENTS = {
  development: {
    url: local,
  },
  staging: {
    url: 'https://admin.universalmetals.local/',
  },
  production: {
    url: 'https://admin.universalmetals.com/',
  },
};

export const envUrl = ENVIRONMENTS[env].url;

export const wp = new WPAPI({ endpoint: `${envUrl}wp-json` });
const namespace = 'wp/v2';

export const metalConfigs = [
  {
    id: 0,
    name: 'Titanium',
    shortName: 'titanium',
    specName: 'titaniumSpec',
    slug: 'titanium',
    apiEndPoint: '/titanium_grades/(?P<id>)',
    specsEndPoint: '/titanium_specs/(?P<id>)',
    description: 'Universal Metals titanium inventory. Come see our titanium grades and specs.',
    seoTitle: 'Titanium Metals Inventory',
  },
  {
    id: 1,
    name: 'Stainless Steel',
    shortName: 'steel',
    specName: 'steelSpec',
    slug: 'stainless-steel',
    apiEndPoint: '/stainless_grades/(?P<id>)',
    specsEndPoint: '/stainlesssteelspec/(?P<id>)',
    description: 'Universal Metals stainless steel inventory. Come see our stainless steel grades and specs.',
    seoTitle: 'Stainless Steel Inventory',
  },
  {
    id: 2,
    name: 'Alloy Steel',
    shortName: 'alloy',
    specName: 'alloySpec',
    slug: 'alloy-steel',
    apiEndPoint: '/alloy_steel_grades/(?P<id>)',
    specsEndPoint: '/alloy_steel_specs/(?P<id>)',
    description: 'Universal Metals alloy steel inventory. Come see our alloy steel grades and specs.',
    seoTitle: 'Alloy Steel Inventory',
  },
  {
    id: 3,
    name: 'Nickel',
    shortName: 'nickel',
    specName: 'nickelSpec',
    slug: 'nickel',
    apiEndPoint: '/nickel_grades/(?P<id>)',
    specsEndPoint: '/nickel_specs/(?P<id>)',
    description: 'Universal Metals nickel inventory. Come see our nickel grades and specs.',
    seoTitle: 'Nickel Inventory',
  },
  {
    id: 4,
    name: 'Cobalt Alloy',
    shortName: 'cobalt',
    specName: 'cobaltSpec',
    slug: 'cobalt-alloy',
    apiEndPoint: '/cobalt_alloy_grades/(?P<id>)',
    specsEndPoint: '/cobalt_alloy_specs/(?P<id>)',
    description: 'Universal Metals cobalt alloy inventory. Come see our cobalt alloy grades and specs.',
    seoTitle: 'Cobalt Alloy Inventory',
  },
];

/**
 * Register Routes
 */
 
metalConfigs.forEach(config => {
  wp[config.shortName] = wp.registerRoute(namespace, config.apiEndPoint);
  wp[config.specName] = wp.registerRoute(namespace, config.specsEndPoint);
});

wp.form = wp.registerRoute(namespace, '/forms/(?P<id>)');

export const getCurrentMetal = metalSlug =>
  metalConfigs.find(config => config.slug === metalSlug);
